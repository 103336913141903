import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { Link, useNavigate } from "react-router-dom"

import Topbar from '../elements/Topbar'
import DataNotice from '../elements/DataNotice'
import Table from '../elements/Table'

import KeysData from '../../xdefiant-tier-list-94a2e18545bd.json'

import ACR from '../images/ACR_6.8.webp'
import G_93R from '../images/93R.webp'
import G_686_Magnum from '../images/686_Magnum.webp'
import AA12 from '../images/AA-12.webp'
import Ak47 from '../images/AK-47.webp'
import D50 from '../images/D50.webp'
import Double_Barrel from '../images/Double_Barrel.webp'
import M4A1 from '../images/M4A1.webp'
import M9 from '../images/M9.webp'
import M16A4 from '../images/M16A4.webp'
import M44 from '../images/M44.webp'
import M60 from '../images/M60.webp'
import M249 from '../images/M249.webp'
import M870 from '../images/M870.webp'
import M1911 from '../images/M1911.webp'
import MK_20_SSR from '../images/MK_20_SSR.webp'
import MP5A2 from '../images/MP5A2.webp'
import MP7 from '../images/MP7.webp'
import P90 from '../images/P90.webp'
import RPK_74 from '../images/RPK-74.webp'
import SVD from '../images/SVD.webp'
import TAC_50 from '../images/TAC-50.webp'
import Vector_45 from '../images/Vector_.45_ACP.webp'
import MDR from '../images/MDR.webp'

import Cleaners from '../images/Cleaners_Logo.webp'
import DedSec from '../images/DedSec_Logo.webp'
import Echelon from '../images/Echelon_Logo.webp'
import Libertad from '../images/Libertad_Logo.webp'
import Phantoms from '../images/Phantoms_Logo.webp'

import Arena from '../images/Arena.webp'
import Attica_Heights from '../images/Attica_Heights.webp'
import Mayday from '../images/Mayday.webp'
import Nudleplex from '../images/Nudleplex.webp'
import Pueblito from '../images/Pueblito.webp'
import Zoo from '../images/Zoo.webp'
import Echelon_HQ from '../images/Echelon_HQ.webp'
import Meltdown from '../images/Meltdown.webp'
import Midway from '../images/Midway.webp'
import Showtime from '../images/Showtime.webp'
import Times_Sqaure from '../images/Times_Square.webp'
import Liberty from '../images/Liberty.webp'
import Emporium from '../images/Emporium.webp'
import Dumbo from '../images/Dumbo.webp'

import MobileList from '../elements/MobileList'

const Home = (props) => {

    const [isNarrow, setIsNarrow] = useState(false);
    const divRef = useRef(null);
  
    useEffect(() => {
        const handleResize = () => {
            console.log(divRef.current.offsetWidth)
            if (divRef.current.offsetWidth < 501)
            {
                console.log('is narrow = true')
                setIsNarrow(true)
            }
            else
            {
                console.log('is narrow = false')
                setIsNarrow(false)
            }
        }
        
        handleResize()

        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
    },[divRef.current])

    const [category, setCategory] = useState(0)
    const [titleCategory, setTitleCategory] = useState(0)

    const mapData = [
        {
            title: 'Echelon HQ',
            image: Echelon_HQ,
            class: '',
            survey: 'https://forms.gle/yTYv1rHq98N9aF5HA',
        },
        {
            title: 'Midway',
            image: Midway,
            class: '',
            survey: 'https://forms.gle/WK1pnE8nYArqg1Rs7',
        },
        {
            title: 'Showtime',
            image: Showtime,
            class: '',
            survey: 'https://forms.gle/3LZcKnvzpLcKsLjz9',
        },
        {
            title: 'Times Square',
            image: Times_Sqaure,
            class: '',
            survey: 'https://forms.gle/bbQyZnk6FtUAp6fj8',
        },
        {
            title: 'Liberty',
            image: Liberty,
            class: '',
            survey: 'https://forms.gle/A8uCTpS1G8CbThkL9',
        },
        {
            title: 'Emporium',
            image: Emporium,
            class: '',
            survey: 'https://forms.gle/z7jxv2bu6nw9AoP47',
        },
        {
            title: 'Dumbo',
            image: Dumbo,
            class: '',
            survey: 'https://forms.gle/dFv51jA34T7fM3xaA',
        },
        {
            title: 'Nudleplex',
            image: Nudleplex,
            class: '',
            survey: 'https://forms.gle/1TUUT3Qvu9ebLsYz6',
        },
        {
            title: 'Arena',
            image: Arena,
            class: '',
            survey: 'https://forms.gle/4x3t8xjdojVTetvY6',
        },
        {
            title: 'Meltdown',
            image: Meltdown,
            class: '',
            survey: 'https://forms.gle/HNExtCvY12KjXxZH9',
        },
        {
            title: 'Attica Heights',
            image: Attica_Heights,
            class: '',
            survey: 'https://forms.gle/mqDNb8HcUfAwMq4X6',
        },
        {
            title: 'Pueblito',
            image: Pueblito,
            class: '',
            survey: 'https://forms.gle/GGG5ZcYHELv1cmys9',
        },
        {
            title: 'Mayday',
            image: Mayday,
            class: '',
            survey: 'https://forms.gle/9MYqKQNZxpHhh8Qd9',
        },
        {
            title: 'Zoo',
            image: Zoo,
            class: '',
            survey: 'https://forms.gle/QErXDMKVH7rJ8ELR6',
        },
    ]

    const factionData = [
        {
            title: 'Cleaners',
            image: Cleaners,
            class: 'Assault',
            survey: 'https://forms.gle/hFb2Gouogi4Sfobm7',
        },
        {
            title: 'DedSecs',
            image: DedSec,
            class: 'Support',
            survey: 'https://forms.gle/itaAS3YBAXmbw3Mh6',
        },
        {
            title: 'Phantoms',
            image: Phantoms,
            class: 'Tank',
            survey: 'https://forms.gle/EpffpgQXmkiT4kkcA',
        },
        {
            title: 'Echelons',
            image: Echelon,
            class: 'Assault',
            survey: 'https://forms.gle/31uA3fRUG11qtZpi7',
        },
        {
            title: 'Libertads',
            image: Libertad,
            class: 'Healer',
            survey: 'https://forms.gle/ouS4mi1Us9kTKd8r6',
        },
    ]

    const weaponData = [
        {
            title: 'ACR',
            image: ACR,
            class: 'Assault Rifle',
            survey: 'https://forms.gle/ZgiHmt9PnTmQJzLx9'
        },
        {
            title: 'MDR',
            image: MDR,
            class: 'Assault Rifle',
            survey: 'https://forms.gle/VFNqwHwrcM58dGJWA'
        },
        {
            title: 'Ak-47',
            image: Ak47,
            class: 'Assault Rifle',
            survey: 'https://forms.gle/7Gap7Tvqb76T7g6B7'
        },
        {
            title: 'M4A1',
            image: M4A1,
            class: 'Assault Rifle',
            survey: 'https://forms.gle/ytEL9L3UgA3YdGcj6'
        },
        {
            title: 'M16A4',
            image: M16A4,
            class: 'Assault Rifle',
            survey: 'https://forms.gle/59uTeBz5D5B1uAS16'
        },
        {
            title: '686 Magnum',
            image: G_686_Magnum,
            class: 'Pistol',
            survey: 'https://forms.gle/WUBSsZVETEAF6tiy6'
        },
        {
            title: 'D50',
            image: D50,
            class: 'Pistol',
            survey: 'https://forms.gle/6xhdYxrqhNZsZ9s8A'
        },
        {
            title: '93R',
            image: G_93R,
            class: 'Pistol',
            survey: 'https://forms.gle/ybTbyzY58TLaFKCj9'
        },
        {
            title: 'M9',
            image: M9,
            class: 'Pistol',
            survey: 'https://forms.gle/YjxPjoqrrtALz2hK8'
        },
        {
            title: 'M1911',
            image: M1911,
            class: 'Pistol',
            survey: 'https://forms.gle/HPc7EGpvonk9uAgk8'
        },
        {
            title: 'M60',
            image: M60,
            class: 'Light Machine Gun',
            survey: 'https://forms.gle/P7Botsp6bYDsNcJEA'
        },
        {
            title: 'M249',
            image: M249,
            class: 'Light Machine Gun',
            survey: 'https://forms.gle/znxSNg8MUezD6K9C6'
        },
        {
            title: 'RPK-74',
            image: RPK_74,
            class: 'Light Machine Gun',
            survey: 'https://forms.gle/25BP3yCMEVuDSRfT6'
        },
        {
            title: 'MK 20 SSR',
            image: MK_20_SSR,
            class: 'Marksman Rifle',
            survey: 'https://forms.gle/8x44QRKqfQXXthvH6'
        },
        {
            title: 'SVD',
            image: SVD,
            class: 'Marksman Rifle',
            survey: 'https://forms.gle/F3Ca2nNdqeAF48dZ9'
        },
        {
            title: 'Double Barrel',
            image: Double_Barrel,
            class: 'Shotgun',
            survey: 'https://forms.gle/UqGHrCYDcDipGDCs9'
        },
        {
            title: 'M870',
            image: M870,
            class: 'Shotgun',
            survey: 'https://forms.gle/1XYe7wfZaqyGHD2E9'
        },
        {
            title: 'AA-12',
            image: AA12,
            class: 'Shotgun',
            survey: 'https://forms.gle/dujw5RxyeFKhCVF97'
        },
        {
            title: 'TAC-50',
            image: TAC_50,
            class: 'Sniper Rifle',
            survey: 'https://forms.gle/AjAYz9gWr3ctmttn7'
        },
        {
            title: 'M44',
            image: M44,
            class: 'Sniper Rifle',
            survey: 'https://forms.gle/9ttyW1z8t3wEzvQL6'
        },
        {
            title: 'MP7',
            image: MP7,
            class: 'Submachine Gun',
            survey: 'https://forms.gle/E5wAMeL4A4d9KPYe6'
        },
        {
            title: 'P90',
            image: P90,
            class: 'Submachine Gun',
            survey: 'https://forms.gle/pdhYVSs1ymAQ5T7VA'
        },
        {
            title: 'Vector .45 ACP',
            image: Vector_45,
            class: 'Submachine Gun',
            survey: 'https://forms.gle/gRMBoX5pbCgMTHGC8'
        },
        {
            title: 'MP5A2',
            image: MP5A2,
            class: 'Submachine Gun',
            survey: 'https://forms.gle/XCTMWYV1P6oiKhrG9'
        },
    ];    

    
    const mapTitleData = [
        {
          Header: 'Map',
          accessor: 'map',
          Cell: ({ cell: { value } }) => (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <span style={{marginRight: '20px'}}>{value.name}</span>
              <img src={value.image} alt='map'/>
            </div>
          )
        },
        {
            Header: 'Tier',
            accessor: 'tier',
            Cell: ({ cell: { value } }) => (
              <span style={{color: value.color}}>{value.text}</span>
            )
          },
        { Header: 'Layout', accessor: 'layout' },
        { Header: 'Balance', accessor: 'balance' },
        { Header: 'Aesthetics', accessor: 'aesthetics' },
        { Header: 'Spawn Points', accessor: 'spawnPoints' },
        { Header: 'Map Flow', accessor: 'mapFlow' },
        { Header: 'Path Variety', accessor: 'pathVariety' },
        { Header: 'Objective Placement', accessor: 'objectivePlacement' },
        { Header: 'Fun Factor', accessor: 'funFactor' },
        {
            Header: 'Survey',
            accessor: 'link',
            Cell: ({ cell: { value } }) => (
                <button onClick={() => window.open(value, '_blank')} style={{backgroundColor: '#004ABA', color: 'white', padding: '10px 30px 10px 30px', borderRadius: '5px', fontFamily: 'inter'}}>Survey</button>
            )
          },
    ]

    const factionTitleData = [
        {
          Header: 'Faction',
          accessor: 'faction',
          Cell: ({ cell: { value } }) => (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <span style={{marginRight: '20px'}}>{value.name}</span>
              <img src={value.image} alt='faction'/>
            </div>
          )
        },
        {
            Header: 'Tier',
            accessor: 'tier',
            Cell: ({ cell: { value } }) => (
              <span style={{color: value.color}}>{value.text}</span>
            )
          },
        { Header: 'Style', accessor: 'style' },
        { Header: 'Abilities', accessor: 'abilities' },
        { Header: 'Ultimate', accessor: 'ultimate' },
        { Header: 'Escort', accessor: 'Escort' },
        { Header: 'Zone Control', accessor: 'zoneControl' },
        { Header: 'Domination', accessor: 'domination' },
        { Header: 'Occupy', accessor: 'occupy' },
        { Header: 'Hot Shot', accessor: 'hotShot' },
        { Header: 'Fun Factor', accessor: 'funFactor' },
        {
            Header: 'Survey',
            accessor: 'link',
            Cell: ({ cell: { value } }) => (
                <button onClick={() => window.open(value, '_blank')} style={{backgroundColor: '#004ABA', color: 'white', padding: '10px 30px 10px 30px', borderRadius: '5px', fontFamily: 'inter'}}>Survey</button>
            )
          },
    ]

    const weaponTitleData = 
    [
        {
            Header: 'Weapon',
            accessor: 'weapon',
            Cell: ({ cell: { value } }) => (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                <span style={{marginRight: '20px'}}>{value.name}</span>
                <img src={value.image} alt='weapon'/>
            </div>
            )
        },
        {
            Header: 'Tier',
            accessor: 'tier',
            Cell: ({ cell: { value } }) => (
                <span style={{color: value.color}}>{value.text}</span>
            )
            },
        { Header: 'Class', accessor: 'class' },
        { Header: 'DPS', accessor: 'dps' },
        { Header: 'Range', accessor: 'range' },
        { Header: 'Accuracy', accessor: 'accuracy' },
        { Header: 'Magazine Size', accessor: 'magazineSize' },
        { Header: 'ADS Time', accessor: 'adsTime' },
        { Header: 'Reload Time', accessor: 'reloadTime' },
        { Header: 'Fun Factor', accessor: 'funFactor' },
        {
            Header: 'Survey',
            accessor: 'link',
            Cell: ({ cell: { value } }) => (
                <button onClick={() => window.open(value, '_blank')} style={{backgroundColor: '#004ABA', color: 'white', padding: '10px 30px 10px 30px', borderRadius: '5px', fontFamily: 'inter'}}>Survey</button>
            )
            },
        ]
    

    const catSelector = [
        {
            title: 'Weapons',
            sheetID: '1rld-b_fN7eBU87tgd1_WlIEmCsOS0VSqv_pNWu9HLSE',
            data: weaponData,
            tableTitles: weaponTitleData,
        },
        {
            title: 'Factions',
            sheetID: '1KRjg53pkgkqb_RruiXq6YKJEKboXn2e9NE-OCBSiPuw',
            data: factionData,
            tableTitles: factionTitleData,
        },
        {
            title: 'Maps',
            sheetID: '1WOkCtBU2riNHUfv355u4my5jV-OEWQcUwe2H-IAuhXY',
            data: mapData,
            tableTitles: mapTitleData,
        },
    ]


    const [columns, setColumns] = useState([
        {
          Header: 'Weapon',
          accessor: 'weapon',
          Cell: ({ cell: { value } }) => (
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
              <span style={{marginRight: '20px'}}>{value.name}</span>
              <img src={value.image} alt='weapon'/>
            </div>
          )
        },
        {
            Header: 'Tier',
            accessor: 'tier',
            Cell: ({ cell: { value } }) => (
              <span style={{color: value.color}}>{value.text}</span>
            )
          },
        { Header: 'Class', accessor: 'class' },
        { Header: 'DPS', accessor: 'dps' },
        { Header: 'Range', accessor: 'range' },
        { Header: 'Accuracy', accessor: 'accuracy' },
        { Header: 'Magazine Size', accessor: 'magazineSize' },
        { Header: 'ADS Time', accessor: 'adsTime' },
        { Header: 'Reload Time', accessor: 'reloadTime' },
        { Header: 'Fun Factor', accessor: 'funFactor' },
        {
            Header: 'Survey',
            accessor: 'link',
            Cell: ({ cell: { value } }) => (
                <button onClick={() => window.open(value, '_blank')} style={{backgroundColor: '#004ABA', color: 'white', padding: '10px 30px 10px 30px', borderRadius: '5px', fontFamily: 'inter'}}>Survey</button>
            )
          },
    ])
    
    const [data, setData] = useState([])
    const [factionTableData, setFactionTableData] = useState([])
    const [mapsTableData, setMapsTableData] = useState([])

    async function getSheetData() {
        const apiKey = 'AIzaSyCRc56mhfZ5lXGRMeZ_0vbmw4kSgGd0OWA';
        const url = `https://sheets.googleapis.com/v4/spreadsheets/${catSelector[category].sheetID}?includeGridData=true&key=${apiKey}`;

        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            processData(data)
        } catch (error) {
            console.log(error)
            document.getElementById('result').textContent = `Error fetching the sheet data: ${error.message}`;
        }
    }

    function extractFormattedValues(data) {
        const extractedData = data.map(row => 
            row.values.map(cell => cell.formattedValue)
        );
        
        // Remove the first element in the array
        extractedData.shift();
        
        return extractedData;
    }

    function calculateAverageAndSort(array) {
        return array.map(obj => {
            const numericKeys = Object.keys(obj).filter(key => typeof obj[key] === 'number');
            const sum = numericKeys.reduce((acc, key) => acc + obj[key], 0);
            const average = sum / numericKeys.length;
            return { ...obj, average };
        }).sort((a, b) => b.average - a.average);
    }

    const processData = (data) => {
        console.log(data)
        let sheets = []
        data.sheets.map((sheet, index) => {
            sheets.push({
                title: sheet.properties.title,
                data: calculateAverages(mapArraysToObjects(extractFormattedValues(sheet.data[0].rowData)))
            })
        })
        sheets = transformData(sheets)
        console.log(sheets)
        sheets = calculateAverageAndSort(sheets)
        if (category === 0)
        {
            setData(sheets)
        }
        else if (category === 1)
        {
            setFactionTableData(sheets)
        }
        else if (category === 2)
        {
            setMapsTableData(sheets)
        }
    }

    function mapArraysToObjects(arrays) {
        return arrays.map(array => {

            let tempDate = []

            if (category === 0)
            {
                return {
                    timestamp: array[0],
                    dps: array[1],
                    range: array[2],
                    accuracy: array[3],
                    magazineSize: array[4],
                    adsTime: array[5],
                    reloadTime: array[6],
                    funFactor: array[7]
                };
            }
            else if (category === 1)
            {
                return {
                    timestamp: array[0],
                    abilities: array[1],
                    ultimate: array[2],
                    escort: array[3],
                    zoneControl: array[4],
                    domination: array[5],
                    occupy: array[6],
                    hotShot: array[7],
                    funFactor: array[8]
                };
            }
            else if (category === 2)
            {
                return {
                    timestamp: array[0],
                    layout: array[1],
                    balance: array[2],
                    aesthetics: array[3],
                    spawnPoints: array[4],
                    mapFlow: array[5],
                    pathVariety: array[6],
                    objectivePlacement: array[7],
                    funFactor: array[8]
                };
            }        
        });
    }

    function calculateAverages(data) {
        let totals = {};
        if (category === 0)
        {
            totals = {
                dps: 0,
                range: 0,
                accuracy: 0,
                magazineSize: 0,
                adsTime: 0,
                reloadTime: 0,
                funFactor: 0
            }
        }
        else if (category === 1)
        {
            totals = {
                abilities: 0,
                ultimate: 0,
                escort: 0,
                zoneControl: 0,
                domination: 0,
                occupy: 0,
                hotShot: 0,
                funFactor: 0,
            }
        }
        else if (category === 2)
        {
            totals = {
                layout: 0,
                balance: 0,
                aesthetics: 0,
                spawnPoints: 0,
                mapFlow: 0,
                pathVariety: 0,
                objectivePlacement: 0,
                funFactor: 0,
            }
        }

        const count = data.length;
    
        data.forEach(item => {
            if (category === 0)
            {
                totals.dps += parseFloat(item.dps);
                totals.range += parseFloat(item.range);
                totals.accuracy += parseFloat(item.accuracy);
                totals.magazineSize += parseFloat(item.magazineSize);
                totals.adsTime += parseFloat(item.adsTime);
                totals.reloadTime += parseFloat(item.reloadTime);
                totals.funFactor += parseFloat(item.funFactor);
            }
            else if (category === 1)
            {
                totals.abilities += parseFloat(item.abilities);
                totals.ultimate += parseFloat(item.ultimate);
                totals.escort += parseFloat(item.escort);
                totals.zoneControl += parseFloat(item.zoneControl);
                totals.domination += parseFloat(item.domination);
                totals.occupy += parseFloat(item.occupy);
                totals.hotShot += parseFloat(item.hotShot);
                totals.funFactor += parseFloat(item.funFactor);
            }
            else if (category === 2)
            {
                totals.layout += parseFloat(item.layout)
                totals.balance += parseFloat(item.balance);
                totals.aesthetics += parseFloat(item.aesthetics);
                totals.spawnPoints += parseFloat(item.spawnPoints);
                totals.mapFlow += parseFloat(item.mapFlow);
                totals.pathVariety += parseFloat(item.pathVariety);
                totals.objectivePlacement += parseFloat(item.objectivePlacement);
                totals.funFactor += parseFloat(item.funFactor);
            }
        });
    
        if (category === 0)
        {
            return {
                dps: totals.dps / count,
                range: totals.range / count,
                accuracy: totals.accuracy / count,
                magazineSize: totals.magazineSize / count,
                adsTime: totals.adsTime / count,
                reloadTime: totals.reloadTime / count,
                funFactor: totals.funFactor / count
            };
        }
        else if (category === 1)
        {
            return {
                abilities: totals.abilities / count,
                ultimate: totals.ultimate / count,
                escort: totals.escort / count,
                zoneControl: totals.zoneControl / count,
                domination: totals.domination / count,
                occupy: totals.occupy / count,
                hotShot: totals.hotShot / count,
                funFactor: totals.funFactor / count
            };
        }
        else if (category === 2)
        {
            return {
                layout: totals.layout / count,
                balance: totals.balance / count,
                aesthetics: totals.aesthetics / count,
                spawnPoints: totals.spawnPoints / count,
                mapFlow: totals.mapFlow / count,
                pathVariety: totals.pathVariety / count,
                objectivePlacement: totals.objectivePlacement / count,
                funFactor: totals.funFactor / count
            };
        }
    }

    const getWeaponClass = (name) => {
        let className = ''
        catSelector[category].data.map(weapon => {
            if (name === weapon.title)
            {
                className = weapon.class;
            }
        })
        return className;
    }

    const getWeaponImage = (name) => {
        let image = ''
        catSelector[category].data.map(weapon => {
            if (name === weapon.title)
            {
                image = weapon.image;
            }
        })
        return image;
    }

    const getFormLink = (name) => {
        let link = ''
        catSelector[category].data.map(weapon => {
            if (name === weapon.title)
            {
                link = weapon.survey;
            }
        })
        return link;
    }

    function transformData(inputData) {
        console.log(inputData)
        if (Array.isArray(inputData))
        {
            return inputData.map(item => {
                if (category === 0)
                {
                    return {
                        weapon: { name: item.title, image: getWeaponImage(item.title) },
                        class: getWeaponClass(item.title),  // You may want to adjust this value if you have specific classes
                        dps: item.data.dps,
                        range: item.data.range,
                        accuracy: item.data.accuracy,
                        magazineSize: item.data.magazineSize,
                        adsTime: item.data.adsTime,
                        reloadTime: item.data.reloadTime,
                        link: getFormLink(item.title),
                        funFactor: item.data.funFactor,
                        tier: {
                            "color": window.getTextColorAndTier((item.data.funFactor + item.data.reloadTime + item.data.adsTime + item.data.magazineSize + item.data.accuracy + item.data.range + item.data.dps)/7).color,
                            "text": window.getTextColorAndTier((item.data.funFactor + item.data.reloadTime + item.data.adsTime + item.data.magazineSize + item.data.accuracy + item.data.range + item.data.dps)/7).text,
                        },
                    };
                }
                else if (category === 1)
                {
                    return {
                        faction: { name: item.title, image: getWeaponImage(item.title) },
                        class: getWeaponClass(item.title),  // You may want to adjust this value if you have specific classes
                        style: item.data.style,
                        abilities: item.data.abilities,
                        ultimate: item.data.ultimate,
                        escort: item.data.escort,
                        zoneControl: item.data.zoneControl,
                        domination: item.data.domination,
                        occupy: item.data.occupy,
                        hotShot: item.data.hotShot,
                        link: getFormLink(item.title),
                        funFactor: item.data.funFactor,
                        tier: {
                            "color": window.getTextColorAndTier((item.data.funFactor + item.data.abilities + item.data.ultimate + item.data.escort + item.data.zoneControl + item.data.domination + item.data.occupy + item.data.hotShot)/8).color,
                            "text": window.getTextColorAndTier((item.data.funFactor + item.data.abilities + item.data.ultimate + item.data.escort + item.data.zoneControl + item.data.domination + item.data.occupy + item.data.hotShot)/8).text,
                        },
                    };
                }
                else if (category === 2)
                {
                    return {
                        map: { name: item.title, image: getWeaponImage(item.title) },
                        layout: item.data.layout,
                        balance: item.data.balance,
                        aesthetics: item.data.aesthetics,
                        spawnPoints: item.data.spawnPoints,
                        mapFlow: item.data.mapFlow,
                        pathVariety: item.data.pathVariety,
                        objectivePlacement: item.data.objectivePlacement,
                        link: getFormLink(item.title),
                        funFactor: item.data.funFactor,
                        tier: {
                            "color": window.getTextColorAndTier((item.data.layout + item.data.balance + item.data.aesthetics + item.data.spawnPoints + item.data.mapFlow + item.data.pathVariety + item.data.objectivePlacement + item.data.funFactor)/8).color,
                            "text": window.getTextColorAndTier((item.data.layout + item.data.balance + item.data.aesthetics + item.data.spawnPoints + item.data.mapFlow + item.data.pathVariety + item.data.objectivePlacement + item.data.funFactor)/8).text,
                        },
                    }; 
                }
            });
        }
        else
        {
            return [];
        }
    }

    useEffect(() => {
        getSheetData()
    },[category])


    const getMapsData = () => {

    }

    const getClassesData = () => {
        
    }

    return (
        <div ref={divRef} style={{width: '100vw', height: '100vh', backgroundColor: window.colors.topbar, overflow: 'hidden', display: 'flex', justifyContent: 'center'}}>
            <div style={{width: '80%', height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Topbar isNarrow={isNarrow} selected={category} setCategory={setCategory}></Topbar>
                <DataNotice isNarrow={isNarrow}></DataNotice>
                <div className='element-with-scrollbar' style={{flexGrow: 1, overflowY: 'scroll', paddingRight: '20px'}}>
                    {category === 0 ? isNarrow ? '':<Table columns={weaponTitleData} data={data}></Table>:''}
                    {category === 1 ? isNarrow ? '':<Table columns={factionTitleData} data={factionTableData}></Table>:''}
                    {category === 2 ? isNarrow ? '':<Table columns={mapTitleData} data={mapsTableData}></Table>:''}
                    {isNarrow ? 
                    <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column'}}>
                        {category === 0 ? data.map((item, index) => { 
                            return (<MobileList item={item} category={category}></MobileList>)
                        }):''}
                        {category === 1 ? factionTableData.map((item, index) => { 
                            return (<MobileList item={item} category={category}></MobileList>)
                        }):''}
                        {category === 2 ? mapsTableData.map((item, index) => { 
                            return (<MobileList item={item} category={category}></MobileList>)
                        }):''}
                    </div>:''}
                </div>
            </div>
        </div>
    )
}

export default Home
