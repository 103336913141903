import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

const Main = (props) => {

    return (
        <div style={{width: '100%', height: props.isNarrow ? 'auto':'64px', minHeight: '64px', display: 'flex', flexDirection: props.isNarrow ? 'column':'row', alignContent: 'center', alignItems: 'center', color: window.colors.font, fontWeight: 'bold'}}>
            <div onClick={() => props.setCategory(0)} style={{width: 'auto', paddingLeft: '30px', paddingRight: '30px', height:'100%', display: 'flex', alignItems: 'center', backgroundColor: props.selected === 0 ? window.colors.highlight:'', cursor: 'pointer', minHeight: props.isNarrow ? '50px':'auto'}}>Weapons</div>
            <div onClick={() => props.setCategory(1)} style={{width: 'auto', paddingLeft: '30px', paddingRight: '30px', height:'100%', display: 'flex', alignItems: 'center', backgroundColor: props.selected === 1 ? window.colors.highlight:'', cursor: 'pointer', minHeight: props.isNarrow ? '50px':'auto'}}>Factions</div>
            <div onClick={() => props.setCategory(2)} style={{width: 'auto', paddingLeft: '30px', paddingRight: '30px', height:'100%', display: 'flex', alignItems: 'center', backgroundColor: props.selected === 2 ? window.colors.highlight:'', cursor: 'pointer', minHeight: props.isNarrow ? '50px':'auto'}}>Maps</div>
            <div style={{flexGrow: 1}}></div>
        </div>
    )
}

export default Main
