import logo from './logo.svg';
import './App.css';

import React, { Component, useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, withRouter, BrowserRouter } from "react-router-dom";
import { Switch } from "react-router"
import { NavLink, Link, useNavigate } from "react-router-dom"

import Home from './components/pages/Home'

function App() {

  const [colors, setColors] = useState({
    "topbar": '#1B222D',
    "background": "#29313E",
    "highlight": '#29313E',
    "font": 'white',
    "selected": '#3C4656'
  })

  useEffect(() => {
    window.colors = colors;
  },[colors])

  const baseURL = 'url'

  function getCurrentTimeFormatted() {
    const now = new Date();

    let hours = now.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12; // Convert to 12-hour format
    hours = hours ? hours : 12; // The hour '0' should be '12'

    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
  }

  let responseFromServer = '';

  window.apiRequest = (endpoint, requestOptions, operation) => {
    let accessToken = localStorage.getItem('access_token')
    let refreshToken = localStorage.getItem('refresh_token')

    var myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append("Authorization", "Bearer " + refreshToken); 
      requestOptions.headers.set('Authorization', "Bearer " + accessToken)
      fetch(baseURL + endpoint, requestOptions)
      .then(response => {responseFromServer = response.status; return response.json()})
      .then(result => {operation(result, responseFromServer)})
  }

  window.getServerResponse = (json) => {
    console.log()
    console.log(json)
    if (typeof json.detail === 'string' || json.detail instanceof String) {
      return (getCurrentTimeFormatted() + ': ' + json.detail)
    } else {
      return json.detail.map((error, index) => {
        return (getCurrentTimeFormatted() + ': ' + error.msg + ' at ' + error.loc.join(', ')).replace(/string/g, "text");
      })
    }
  }

  window.getTextColorAndTier = (rating) => {
    if (rating >= 9.8) {
        return {color: '#FF0000', text: 'S+'} // Bright Red
    }
    if (rating >= 9.3) {
        return {color: '#FF3030', text: 'S'} // Bright Firebrick
    }
    if (rating >= 8.8) {
        return {color: '#FF6347', text: 'S-'} // Bright Orange Red
    }
    if (rating >= 8.3) {
        return {color: '#FFB700', text: 'A+'} // Bright Goldenrod
    }
    if (rating >= 7.8) {
        return {color: '#FFEB00', text: 'A'} // Bright Gold
    }
    if (rating >= 7.4) {
        return {color: '#FFFACD', text: 'A-'} // Bright Light Gold
    }
    if (rating >= 7.1) {
        return {color: '#DA70D6', text: 'B+'} // Bright Purple
    }
    if (rating >= 6.8) {
        return {color: '#BA55D3', text: 'B'} // Bright Blue Violet
    }
    if (rating >= 6.5) {
        return {color: '#DDA0DD', text: 'B-'} // Bright Medium Purple
    }
    if (rating >= 6.4) {
        return {color: '#5F9EA0', text: 'C+'} // Bright Steel Blue
    }
    if (rating >= 6.3) {
        return {color: '#00CFFF', text: 'C'} // Bright Deep Sky Blue
    }
    if (rating >= 6.2) {
        return {color: '#B0E2FF', text: 'C-'} // Bright Light Blue
    }
    if (rating >= 5.8) {
        return {color: '#00FF00', text: 'D+'} // Bright Green
    }
    if (rating >= 5.4) {
        return {color: '#00FF7F', text: 'D'} // Bright Lime Green
    }
    if (rating >= 5.0) {
        return {color: '#ADFF2F', text: 'D-'} // Bright Chartreuse
    }
    return {color: '#D3D3D3', text: 'F'} // Bright Light Gray
}

  window.colors = colors;

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<Home/>}>

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
