import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

const Main = (props) => {

    return (
        <div style={{display: 'flex', flexDirection: 'column', backgroundColor: window.colors.highlight, display: 'flex', flexDirection: 'column', color: 'white', textAlign: 'center', marginTop: '30px'}}>
            {props.category === 0 ?<div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{fontSize: '20px'}}>{props.item.weapon.name}</span>
                <img src={props.item.weapon.image}></img>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background, color: props.item.tier.color}}>Tier - {props.item.tier.text}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>DPS - {props.item.dps}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Range - {props.item.range}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Accuracy - {props.item.accuracy}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Magazine Size - {props.item.magazineSize}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>ADS Time - {props.item.adsTime}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Reload Time - {props.item.reloadTime}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Fun Factor - {props.item.funFactor}</div>
                <button onClick={() => window.open(props.item.link, '_blank')} style={{backgroundColor: '#004ABA', color: 'white', padding: '10px 30px 10px 30px', borderRadius: '5px', fontFamily: 'inter'}}>Survey</button>
            </div>:''}
            {props.category === 1 ?<div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{fontSize: '20px'}}>{props.item.faction.name}</span>
                <img style={{width: '80px', height: '80px', margin: 'auto'}} src={props.item.faction.image}></img>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background, color: props.item.tier.color}}>Tier - {props.item.tier.text}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Abilities - {props.item.abilities}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Ultimate - {props.item.ultimate}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Escort - {props.item.escort}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Zone Control - {props.item.zoneControl}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Domination - {props.item.domination}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Occupy - {props.item.occupy}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Hot Shot - {props.item.hotShot}</div>
            </div>:''}
            {props.category === 2 ?<div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{fontSize: '20px'}}>{props.item.map.name}</span>
                <img style={{width: '80px', height: '80px', margin: 'auto'}} src={props.item.map.image}></img>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background, color: props.item.tier.color}}>Tier - {props.item.tier.text}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Layout - {props.item.layout}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Balance - {props.item.balance}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Aesthetics - {props.item.aesthetics}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Spawn Points - {props.item.spawnPoints}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Map Flow - {props.item.mapFlow}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Path Variety - {props.item.pathVariety}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.topbar}}>Objective Placement - {props.item.objectivePlacement}</div>
                <div className='center' style={{width: '100%', height: '42px', backgroundColor: window.colors.background}}>Fun Factor - {props.item.funFactor}</div>
            </div>:''}
        </div>
    )
}

export default Main
