import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

const Main = (props) => {

    return (
        <div style={{width: '100%', height: props.isNarrow ? 'auto':'64px', minHeight: '64px',  display: 'flex', flexDirection: 'row', marginTop: props.isNarrow ? '120px':'0px'}}>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', color: window.colors.font}}>
                <span>All data is crowd sourced through surveys. </span>
                <span>Disagree? Give your opinion here to change the data!</span>
            </div>
            <div className='center' style={{marginLeft: '50px'}}>
                
            </div>
        </div>
    )
}

export default Main
